import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/servicom/1.png';
import { Link } from 'gatsby';
// import blogd2 from '../../assets/images/2022/nurse-ndidi-retire/2.jpg';
// import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
// import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';
// import View from 'react-view-component/lib/View'

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import { flowRight } from 'lodash';
import { left, right } from '@popperjs/core';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                {/* <h2 className='blog-one__title'>
                  NURSE NDIDI ENUWA RETIRES: SPONSORS FREE EYE SCREENING FOR
                  LUTH STAFF
                </h2> */}
                <div className='col-lg-12'>
                  <div className='blog-one__image'>
                    <img src={blogd1} alt='' />
                  </div>
                  {/* <div className='col-lg-4' >
                    <img src={blogd2}  height={370} />
                  </div> */}
                </div>
                {/* <View style={{ height: 1, width: '100%', borderRadius: 1, borderWidth: 1, borderColor: 'red', borderStyle: 'dotted' }} /> */}
                <p
                  className='blog-one__text'
                  style={{
                    marginBottom: 30,
                    textAlign: left,
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <strong>INTRODUCTION:</strong>
                  <br />
                  Historical Background On assumption of duty in October 2006,
                  Prof Akin Osibogun, the newly appointed Chief Medical Director
                  of LUTH, directed a reconstitution of the LUTH SERVICOM
                  Committee. This Committee had been ably headed by Dr Kemi
                  Longe-Peters until she was appointed the Director of the
                  National SERVICOM Office in Abuja. The Committee’s mandate was
                  to implement the Tertiary Health Institutions Service Delivery
                  and Quality Care as formulated by the Federal Ministry of
                  Health and positively impact the LUTH workforce to achieve
                  sustainable quality assurance. At a meeting in January 2007,
                  the Prof Christopher Bode, a Consultant Paediatric Surgeon was
                  appointed the SERVICOM Nodal Officer for LUTH. Other members
                  of the Servicom Committee were Mr B Grillo, Head, Corporate
                  Services, Chief Nursing Officer (Mrs) AT Adejugbe, Mrs TO
                  Ladenika (Pharmacy) Mr OS Alabi (Engineering Services) Mr DB
                  Oderinde Environmental Health and Mr U Emekwue (Laboratory
                  Services). The present members of the LUTH Servicom are:{' '}
                  <br />
                </p>

                <div>
                  <table>
                    <ul
                      style={{
                        marginBottom: 30,
                        textAlign: left,
                        marginLeft: 25,
                        marginRight: 25,
                      }}
                    >
                      <li>Dr AA Roberts- Nodal Officer</li>
                      <li>Ms OO Fakeye- PRO LUTH</li>
                      <li>Mr Wole Ajayi- HOD Medical Records </li>
                      <li>Engr OS Alabi- LUTH Engineering Services</li>
                      <li>DNS OS Adepoju- LUTH Nursing Services</li>
                      <li>
                        Mr Doutimiwei- CSO LUTH Security Unit DNS Luth Annex
                        Yaba
                      </li>
                    </ul>
                  </table>
                </div>
                <p
                  className='blog-one__text'
                  style={{
                    marginBottom: 30,
                    textAlign: left,
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <br />
                  It became apparent that for the Servicom concept to succeed in
                  LUTH, it must be understood and accepted as a user-friendly
                  working tool by all stakeholders. The Committee therefore
                  spent time identifying key interface points between
                  service-providers and clients of LUTH, synthesizing the
                  different requirements of patients and likely bottlenecks
                  where the Servicom Committee and its message might be most
                  useful. Since inception, this model of interaction has been
                  strengthened with constant meetings with departmental heads,
                  members of staff and others to familiarize workers with the
                  compliance requirements of Servicom.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd2} height={370} /> */}
                  </div>
                  <div className='col-lg-12'>
                    {/* <img src={blogd3} height={370} /> */}
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 14,
                    fontStyle: 'italic',
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <strong> Lagos University Teaching Hospital</strong>
                </span>
                <p
                  className='blog-one__text'
                  style={{
                    marginBottom: 30,
                    textAlign: left,
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <br />
                  The Lagos University Teaching Hospital was established in 1962
                  by an act of Parliament to serve as a teaching hospital and
                  research facility to the newly established College of Medicine
                  of the University of Lagos while providing tertiary medical
                  service to the Lagos Mainland community where it was situated.
                  The pioneering team created a reputable set of sustainable
                  standards in keeping with the Hospital’s Vision of providing
                  excellent tertiary care of international standard. Thus two
                  generations of accomplished specialists played prominent roles
                  in the pursuit of excellence within this great edifice in the
                  sixties and seventies. The LUTH brand had long enjoyed
                  enormous appeal, synonymous with last-resort refuge for
                  patients in extremis. It guaranteed salvage from diverse
                  ailments for a population of over 30 millions within Lagos
                  neighboring states and countries, the largest such
                  clientele-base in West Africa. LUTH accepts more tertiary
                  care-related referrals than any other hospital in Nigeria.
                  When medical specialists brain-drained out of many neighboring
                  states in the mid-80s and early 90s, LUTH remained a bastion
                  of surgical excellence as the hospital remained well staffed
                  and resourced and continued to render qualitative service in
                  all disciplines. The hospital therefore experienced little if
                  any major disruptions in staffing during the tumultuous years
                  of emigration of skilled manpower to the Gulf States. The land
                  and purpose-built infrastructure, earlier earmarked for the
                  Mainland General Hospital, were converted into an enormous
                  estate for the newly established LUTH in 1962. This
                  multi-billion Naira, 600-bed facility ranks with the oldest of
                  its kind in this country in size and scope. The likes of such
                  projects not feasible anywhere in West Africa now or in the
                  near future. LUTH has always attracted a large pool of
                  talented specialists in various medical and surgical
                  disciplines, nursing and allied medical professions as well as
                  support staff who provide inpatient care to people requiring
                  close medical monitoring and outpatient care to those in need
                  of treatment but not constant medical attention. They work in
                  a well coordinated manner to provide diagnosis and medical
                  treatment of physical and mental health problems, surgery,
                  rehabilitation, health education programs, nursing and
                  physician training. This is partly because the innate
                  individual and corporate wealth within the city has enabled
                  some degree of patients’ affordability of specialist treatment
                  in both private and public institutions. The Teaching Hospital
                  is sponsored by the Federal Government for both recurrent and
                  capital expenditures through regular subvention thus allowing
                  for discounted pricing of sundry services to patients on who
                  doctors are trained.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd4} height={370} /> */}
                  </div>
                </div>
                <div>
                  <section className='contact-info-one'>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              {/* <i class="bi bi-eye-fill"></i> */}
                              <i className='kipso-icon-targeting'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              VISION OF LUTH{' '}
                            </h2>
                            <p
                              className='contact-info-one__text'
                              style={{
                                marginBottom: 30,
                                marginLeft: 25,
                                marginRight: 25,
                                fontStyle: 'italic',
                              }}
                            >
                              Our Vision in LUTH is in line with the Federal
                              Ministry of Health’s stated Vision viz to reduce
                              the morbidity and mortality due to communicable
                              diseases to the barest minimum, reverse the
                              increasing prevalence of non-communicable
                              diseases, meeting global targets on the
                              elimination and eradication of diseases and
                              significantly increase the life expectancy and
                              quality of life of Nigerians.
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-training'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              MISSION STATEMENT
                            </h2>
                            <p
                              className='contact-info-one__text'
                              style={{
                                marginLeft: 25,
                                marginRight: 25,
                                fontStyle: 'italic',
                              }}
                            >
                              “Our corporate goal, as a foremost tertiary
                              institution of international standard, is to
                              provide patient care, produce medical and health
                              manpower and investigate health-related problems
                              by applying the highest standard of promptness,
                              comprehensiveness and effectiveness. This will be
                              achieved through appropriate communication between
                              staff and patients, their relations, among staff,
                              between LUTH and the public and between LUTH and
                              its professional affiliates. In furtherance of
                              this goal, use will be made of well motivated
                              staff as well as expertise based on the highest
                              skill and experience in an environment that is
                              clean, conducive and patient-friendly”
                            </p>
                          </div>
                        </div>
                        <br />
                        <div>
                          Thus, LUTH has continued to render qualitative
                          tertiary care to a large population while producing
                          world-class medical doctors and other cadres of
                          medical manpower. It is also a foremost medical
                          research centre within West Africa.
                        </div>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              CLIENTS’ ACCESS TO LUTH FACILITIES & SERVICES
                            </h2>
                            <p
                              className='contact-info-one__text'
                              style={{
                                marginLeft: 25,
                                marginRight: 25,
                              }}
                            >
                              <strong>Emergencies </strong>
                              <br />
                              Patients may seek emergency Obstetrics and
                              Gynaecological, Medical and Surgical services at
                              the well manned Accidents & Emergency (A&E) Centre
                              located at the main gate of LUTH on a 24 hour
                              basis. Emergency care of children is performed at
                              the Olikoye Ransome-Kuti Children’s Emergency Room
                              (CHER) near the Mushin gate. All emergencies are
                              seen with or without referral. Elective Cases
                              Non-emergency cases (Electives) are seen on
                              referral at the various Out-Patient Clinics
                              located on the first, second and third floors of
                              the A&E Building. Dental Surgery and
                              Maxillo-facial cases are attended to at the School
                              of Dentistry, near the Mushin Gate while
                              Dermatology and Psychiatry out-patient Clinics are
                              held at the LUTH Annex premises on Harvey Road,
                              Yaba Lagos. Information on specific out-patient
                              clinic days are obtainable at information desks on
                              the ground floor of each building. LUTH also runs
                              a model Primary Health Care Centre at Pakoto in
                              Ogun State catering for the primary medical needs
                              of a suburban community in Ifo Local Government
                              area of Ogun State.
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                            <div
                              style={{
                                marginLeft: 50,
                                marginRight: 100,
                              }}
                            >
                              <h2 className='contact-info-one__title'>
                              Clinical Departments & Facilities
                              </h2>
                              <table className='servicom-table'>
                                <tr>
                                  <th>Departments/ Schools</th>
                                  <th>Services</th>
                                </tr>
                                <tr>
                                  <td>Medicine</td>
                                  <td>
                                    Cardiology, Neurology, Gastroenterology,
                                    <br />
                                    Haematology, Endocrinology Nephrology,
                                    <br />
                                    Dermatology Respiratory Medicine, Renal
                                    Dialysis
                                  </td>
                                </tr>
                                <tr>
                                  <td>Obstetrics & Gynaecology</td>
                                  <td>
                                    Antenatal, Perinatal cares, Family Planning,{' '}
                                    <br />
                                    Prevention of Maternal to Child Transmission{' '}
                                    <br />
                                    of HIV, Oncology & Pathological Studies,
                                    <br />
                                    Ultrasonography & Foetal Monitoring,
                                    <br />
                                    Reproductive Endocrinology & Fertility
                                    <br />
                                    Regulation, Experimental & Maternal Medicine
                                  </td>
                                </tr>
                                <tr>
                                  <td>Paediatrics</td>
                                  <td>
                                    Gastroenterology, Oncology, Haematology,{' '}
                                    <br />
                                    Respiratory Medicine, Cardiology Neurology,
                                    <br />
                                    Nephrology, Neonatology Nutrition,
                                    <br />
                                    Endocrinology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Surgery</td>
                                  <td>
                                    Orthopaedics & Trauma Care, Urology Burns &
                                    Plastic Surgery,
                                    <br /> Neurosurgery, Paediatric Surgery,
                                    <br /> Ophthalmology, Otorhinolaryngology,
                                    <br />
                                    General Surgery Prosthesis Surgical
                                    Endoscopy
                                  </td>
                                </tr>
                                <tr>
                                  <td>Dental Surgery</td>
                                  <td>
                                    Periodontology
                                    <br /> Prosthetic Surgery
                                    <br />
                                    Maxillofacial Surgery <br />
                                    Orthodontics
                                  </td>
                                </tr>
                                <tr>
                                  <td>Anaesthesia</td>
                                  <td>
                                    Anaesthesia, ICU Care <br />
                                    Pain Management
                                  </td>
                                </tr>
                                <tr>
                                  <td>Community Medicine</td>
                                  <td>
                                    Reproductive healthcare services <br />
                                    Family Planning
                                    <br /> Child welfare clinic <br />
                                    Endemic disease clinic <br />
                                    Intellectual disabilities clinic <br />
                                    Community Outreach Programmes <br />
                                    Public health education and community
                                    mobilisation services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Institute of Child Health</td>
                                  <td>
                                    Training of Community Health Officers,
                                    <br />
                                    Primary Health Care Services at Pakoto
                                  </td>
                                </tr>
                                <tr>
                                  <td>Physiotherapy Staff Clinic</td>
                                  <td>
                                    Physiotherapy, Staff Medical Care <br />
                                    NHIS Services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Radiodiagnosis</td>
                                  <td>X-Ray & Imaging Services</td>
                                </tr>
                                <tr>
                                  <td>Radiotherapy</td>
                                  <td>
                                    Radiotherapy <br /> Oncology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Morbid Anatomy </td>
                                  <td>
                                    Histopathology, Cytology <br /> Mortuary
                                    Services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Laboratories</td>
                                  <td>
                                    Chemical Pathology <br /> Haematology <br />
                                    Blood banking
                                    <br />
                                    Microbiology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pharmacy</td>
                                  <td>Pharmaceutical Services</td>
                                </tr>
                                <tr>
                                  <td>Nursing Services</td>
                                  <td>Nursing</td>
                                </tr>
                                <tr>
                                  <td>Psychiatry</td>
                                  <td>Psychiatric services</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-lg-4'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-email'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              For Complaints
                            </h2>
                            <p className='contact-info-one__text'>
                              servicom@luth.gov.ng
                              <br />
                              Servicom Desk Officer: 0803 648 9497
                              <br />
                              Nodal Officer: 0705 259 9941 <br /> &nbsp;
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </section>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
